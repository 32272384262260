import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const PolicyReward = ({ open = false, onBack = () => {} }) => {
  const styleShow = { display: open ? "block" : "none" };

  const goBack = () => {
    onBack();
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [open]);

  return (
    <div style={styleShow}>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pagePolicy-Reward">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                <a className="btnIconBack" onClick={goBack}></a>
                <h2>เงื่อนไขการร่วมกิจกรรมแลกรับของรางวัล</h2>
              </div>
            </section>
            <section id="pContent">
              <div className="bContent bCard">
                <h1>เงื่อนไขการร่วมกิจกรรมแลกรับของรางวัล</h1>
                <div className="bDetails">
                  <h2>
                    1. รายละเอียดของรางวัลสำหรับการใช้พอยต์แลกของรางวัล มีดังนี้{" "}
                    <u>*ของรางวัลมีจำนวนจำกัด</u>
                  </h2>
                  <p>
                    1.1 แก้วน้ำโคอะลา มาร์ช มูลค่า 199 บาท สามารถแลกได้โดยใช้พอยต์สะสม 15 พอยต์ (จำนวนจำกัด 720 สิทธิ์)
                  </p>
                  <p>
                    1.2 กระเป๋าคล้องคอโคอะลา มาร์ช มูลค่า 299 บาท สามารถแลกได้โดยใช้พอยต์สะสม 20 พอยต์ (จำนวนจำกัด 240 สิทธิ์)
                  </p>
                  <p>
                    1.3 กระเป๋านักเรียนโคอะลา มาร์ช มูลค่า 399 บาท สามารถแลกได้โดยใช้พอยต์สะสม 25 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.4 ตุ๊กตาโคอะลา มาร์ช มูลค่า 599 บาท สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.5 รองเท้าสลิปเปอร์โคอะลา มาร์ช มูลค่า 599 บาท สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 400 สิทธิ์)
                  </p>
                  <p>
                    1.6 หมอนรองคอโคอะลา มาร์ช มูลค่า 899 บาท สามารถแลกได้โดยใช้พอยต์สะสม 25 พอยต์ (จำนวนจำกัด 10 สิทธิ์)
                  </p>
                  <p>
                    1.7 รองเท้าสลิปเปอร์โคอะลา มาร์ช มูลค่า 599 บาท สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.8 ตุ๊กตาแองเจิลโคอะลา มาร์ช มูลค่า 699 บาท สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.9 ตุ๊กตาเดวิลล์โคอะลา มาร์ช มูลค่า 699 บาท สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.10 ตุ๊กตาผึ้งโคอะลา มาร์ช มูลค่า 699 บาท สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.11 กระเป๋ารถเข็นพับได้โคอะลา มาร์ช มูลค่า 1,399 บาท สามารถแลกได้โดยใช้พอยต์สะสม 45 พอยต์ (จำนวนจำกัด 150 สิทธิ์)
                  </p>
                  <p>
                    1.12 กระเป๋าเดินทางโคอะลา มาร์ชอูจิมัทฉะ มูลค่า 1,599 บาท สามารถแลกได้โดยใช้พอยต์สะสม 75 พอยต์ (จำนวนจำกัด 100 สิทธิ์)
                  </p>
                  <p>
                    1.13 Let’s Relax voucher มูลค่า 500 บาท สามารถแลกได้โดยใช้พอยต์สะสม 50 พอยต์ (จำนวนจำกัด 50 สิทธิ์)
                  </p>
                  <p>
                    1.14 แก้วน้ำโคอะลา มาร์ช ลายครบครอบ 40 ปี มูลค่า 199 บาท สามารถแลกได้โดยใช้พอยต์สะสม 15 พอยต์ (จำนวนจำกัด 83 สิทธิ์)
                  </p>
                  <p>
                    <strong>
                      <u>หมายเหตุ:</u>
                    </strong>{" "}
                    พอยต์ที่ใช้แลกของรางวัลและแลกสิทธิ์ลุ้นโชคไปแล้ว จะถูกลบออกจากพอยต์สะสม เช่น มีพอยต์สะสม 100 พอยต์ แลกของรางวัล 50 พอยต์ จะทำให้พอยต์สะสมคงเหลือสุทธิ 50 พอยต์ ของรางวัลจะถูกจัดส่งภายใน 60 วันหลังจากที่ท่านแลกของรางวัล
                  </p>

                  <h2>
                    รายละเอียด กติกา และเงื่อนไขการร่วมกิจกรรมและแลกพอยต์รับของรางวัล
                  </h2>
                  <p>
                    1. สำหรับผู้ร่วมกิจกรรมที่แลกพอยต์รับรางวัลจะต้องเป็นบุคคลธรรมดา ไม่จำกัดสัญชาติ มีภูมิลำเนาและถิ่นที่อยู่ปัจจุบันในประเทศไทยเท่านั้น
                  </p>
                  <p>
                    2. หลังจากผู้เข้าร่วมกิจกรรมกดแลกรับของรางวัลแล้ว ของรางวัลจะถูกจัดส่งภายใน 60 วันหลังจากที่กดแลกรับของรางวัล โดยเริ่มนับวันที่ 1 ในวันถัดไป (*ของรางวัลมีจำนวนจำกัด) ท่านสามารถดูจำนวนของรางวัลคงเหลือผ่านเมนู “แลกของรางวัล”
                  </p>
                  <p>
                    3. ของรางวัลไม่สามารถแลก ทอน หรือเปลี่ยนเป็นเงินสดได้ และไม่สามารถโอนสิทธิ์ให้ผู้อื่นได้ บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไข เพิ่ม หรือลดของรางวัลและเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </p>
                  <p>
                    4. หากบริษัทฯ ตรวจสอบภายหลังและพบว่าผู้ได้รับรางวัลไม่อยู่ในเงื่อนไขที่กำหนดหรือทำผิดกติกาไม่ว่าส่วนใดส่วนหนึ่ง บริษัทฯ สามารถยกเลิกสิทธิ์ และมอบสิทธิ์ใหม่ให้ผู้ได้รับรางวัลในรายชื่อสำรองได้ รวมถึงขอสงวนสิทธิ์ในการมอบ, ยกเลิก, เพิกถอน, หรือเรียกคืนรางวัลทั้งหมด รวมทั้งเรียกให้ชดใช้ค่าเสียหาย หากมีการใช้สิทธิ์ร่วมรายการโดยทุจริต, ปลอมแปลง, ฉ้อฉล เพื่อให้ได้มาซึ่งสิทธิ์ในรายการนี้โดยมิชอบ
                  </p>
                  <p>
                    5. หากข้อมูลในระบบสูญหายอันเนื่องมาจากเหตุการณ์ไม่คาดคิดใด ๆ เช่น ภัยธรรมชาติ, การเกิดสถานการณ์ความไม่สงบหรือการโจรกรรมทางคอมพิวเตอร์ ฯลฯ ทางบริษัทฯ จะไม่รับผิดชอบข้อมูลส่วนที่สูญหายนั้น และหากมีเหตุจำเป็นต้องขอข้อมูลใหม่ในช่วงที่กิจกรรมยังไม่สิ้นสุด ทางบริษัทฯ จะแจ้งขอข้อมูลของท่านอีกครั้ง
                  </p>
                  <p>
                    6. พนักงานและครอบครัวของบริษัท ไทยลอตเต้ จำกัด, ตัวแทนบริษัท, พนักงานร้านค้าตัวแทนจำหน่าย, เอเจนซี่, คณะกรรมการดำเนินงาน รวมถึงบริษัทที่เกี่ยวข้องกับการจัดกิจกรรม ไม่มีสิทธิเข้าร่วมหรือรับของรางวัลในกิจกรรมนี้
                  </p>
                  <p>
                    7. กรณีมีข้อขัดแย้งหรือข้อพิพาท คำตัดสินของคณะกรรมการถือเป็นเด็ดขาดและสิ้นสุด
                  </p>
                  <p>
                    8. รูปภาพในกิจกรรมทั้งหมดใช้เพื่อประกอบการโฆษณาเท่านั้น
                  </p>
                  <p>
                    9. บริษัทขอสงวนสิทธิ์ในการปรับเปลี่ยนอัตราหรือจำนวนพอยต์ที่ผู้ใช้งานจะได้รับจากการพิมพ์รหัสในกล่อง Koala’s March และการร่วมกิจกรรมต่าง ๆ ได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </p>
                  <p>
                    10. ผู้ร่วมกิจกรรมเข้าใจและรับทราบเป็นอย่างดีถึงวัตถุประสงค์ของกิจกรรมนี้และตกลงยินยอมที่จะให้บริษัทฯ ทำการเก็บรวบรวมส่วนบุคคลที่มีความอ่อนไหว ใช้ข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรม เฉพาะที่จำเป็นสำหรับการร่วมกิจกรรมเป็นไปตามนโยบายของบริษัทฯ และตามข้อบังคับของกฎหมาย และ/หรือเพื่อให้การเข้าร่วมกิจกรรม, การแจ้งผล และการรับรางวัลในกิจกรรม ตลอดจนการนำข้อมูลดังกล่าวมาประมวลผลของกิจกรรมนี้ว่าบรรลุวัตถุประสงค์สมบูรณ์อย่างแท้จริง ทั้งนี้ท่านควรตรวจสอบรายละเอียดกติกาและเงื่อนไขนี้เป็นระยะเพื่อรับทราบการเปลี่ยนแปลงที่อาจเกิดขึ้น การที่ท่านเข้ามาใช้งานระบบหมายความว่าท่านยอมรับการเปลี่ยนแปลงนั้น
                  </p>
                  <p>
                    11. ทางบริษัทฯ สงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไข และของรางวัลโดยไม่ต้องแจ้งให้ทราบล่วงหน้า กรณีมีเหตุภัยธรรมชาติ หรืออื่นใด อีกทั้งบริษัทฯ จะไม่รับผิดชอบต่อคุณภาพที่อาจเกิดขึ้นกับรางวัลดังกล่าว หากมีปัญหาเกี่ยวกับคุณภาพ หรือบริการ ผู้ร่วมกิจกรรมจะต้องติดต่อบริษัทที่จัดจำหน่ายอย่างเป็นทางการโดยตรง
                  </p>
                  <p>
                    12. การขอเปลี่ยนของรางวัลเนื่องจากของรางวัลชำรุดและเสียหายจากการขนส่ง รวมถึงได้รับของรางวัลผิดประเภทจากที่ตนได้ทำการแลกไป ทางบริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนของรางวัลในกรณีดังกล่าวได้ไม่เกิน 2 ครั้ง โดยจำเป็นต้องส่งของรางวัลที่ชำรุดหรือเสียหายกลับมาให้ทางบริษัทฯ เป็นที่เรียบร้อยก่อน หลังจากนั้นทางบริษัทฯ จึงจะจัดส่งของรางวัลชิ้นใหม่ไปให้ และต้องแจ้งขอเปลี่ยนของรางวัลภายใน 15 วันทำการหลังจากที่ของรางวัลได้ถูกจัดส่งสำเร็จ
                  </p>
                  <p>
                    13. ของรางวัลมีจำนวนจำกัด ท่านสามารถตรวจสอบจำนวนคงเหลือผ่านริชเมนูหัวข้อ “แลกของรางวัล”
                  </p>
                  <p>
                    14. ติดตามกิจกรรมพิเศษและศึกษารายละเอียดเพิ่มเติมได้ที่ LINE OA @koalas-march
                  </p>
                  <p>
                    ข้าพเจ้าได้อ่านและเข้าใจ กติกาและเงื่อนไขนี้เป็นอย่างดีแล้ว และให้ความยินยอมให้ข้อมูลแก่บริษัทฯ ในการใช้ข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อส่งข้อมูลกิจกรรมส่งเสริมการขายและการตลาดต่าง ๆ ที่บริษัทฯ ต้องการจะสื่อสารในแคมเปญต่อ ๆ ไป
                  </p>
                </div>
              </div>
              <div className="bBtn">
                <a onClick={goBack} className="btn">
                  ย้อนกลับ
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PolicyReward;
