import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";

const PolicyTerms = ({ open = false, onBack = () => {}, noBTN = false }) => {
  const styleShow = { display: open ? "block" : "none" };

  const goBack = () => {
    onBack();
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [open]);

  return (
    <div style={styleShow}>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <div id="pagePolicy-Terms">
        <main>
          <div className="wrapContent">
            <section id="pTitlePage">
              <div className="bTitlePage">
                {!noBTN ? (
                  <a className="btnIconBack" onClick={goBack}></a>
                ) : (
                  <a></a>
                )}

                <h2>ข้อกำหนดกติกาและเงื่อนไข</h2>
              </div>
            </section>

            <section id="pContent">
              {/* กิจกรรมที่ 1 */}
              <div className="bContent bCard">
                <h1>เงื่อนไขการลุ้นของรางวัล  </h1>
                <h1 style={{ marginBottom: "10px" }}>
                  กิจกรรมครั้งที่ 1 - ระยะเวลา
                </h1>
                <h1>
                  เริ่มแลกได้ตั้งแต่วันที่ 1 กุมภาพันธ์ 2568 ถึง วันที่ 30
                  เมษายน 2568  {" "}
                </h1>
                <div className="bDetails">
                  <p>
                    1. สมาชิกโคอะลา มาร์ช ที่มีคะแนนสะสม จากการซื้อโคอะลา มาร์ช
                    รสชาติอูจิ มัทฉะ สามารถแลกคะแนนสิทธิ์สำหรับลุ้นชิงโชค
                    โดยมีอัตราการแลกคะแนนคือ ทุก 1 พอยต์ รับ 1 สิทธิ์ 
                  </p>
                  <p>
                    2. สมาชิกโคอะลา มาร์ช สามารถแลกคะแนนได้ไม่จำกัดจำนวนสิทธิ์
                    ตามคะแนนสะสมที่คงเหลือของสมาชิก ณ วันที่สมาชิกทำการแลกคะแนน
                    และการแลกคะแนนเพื่อรับสิทธิ์ลุ้นโชคจะต้องทำผ่านระบบสะสมคะแนนบนไลน์ทางการ
                    @koalas-march เท่านั้น {" "}
                  </p>
                  <p>
                    เมื่อครบกำหนดระยะเวลา ทางบริษัทฯ
                    จะทำการรวบรวมรายชื่อของผู้เข้าร่วมรายการที่แลกคะแนนถูกต้อง
                    โดยทำการพิมพ์รายชื่อ- นามสกุล และวันที่ทำการแลกคะแนน
                    ของทุกคนลงบนกระดาษตัดเป็นชิ้นส่วนตามจำนวนสิทธิ์
                    และนำมาจับรางวัล ณ ห้องสัมมนา บริษัท วินเทอร์อีเจนซี่ จำกัด
                    อาคาร 140 ไวร์เลส ชั้นที่ 17 เลขที่ 140 ถนนวิทยุ แขวงลุมพินี
                    เขตปทุมวัน กรุงเทพมหานคร 10330 บริษัทฯ
                    จะประกาศรายชื่อผู้โชคดีภายใน 7 วัน
                    ตามลำดับการจับรางวัลในแต่ละครั้ง ผ่านทาง เฟซบุ๊กแฟนเพจ Lotte
                    Koala’s March (https://www.facebook.com/ilovekoalamarch)
                    ไลน์ออฟฟิเชียลแอคเคาน์ LINE Official Account:
                    @koalas-march  {" "}
                  </p>
                  <p>
                    3. มีการจับรางวัล 1 ครั้ง ดังนี้  ครั้งที่ 1 เริ่มวันที่ 1
                    กุมภาพันธ์ 2568 สิ้นสุดวันที่ 30 เมษายน 2568
                    กำหนดจับรางวัลวันที่ 30 พฤษภาคม 2568 เวลา 11.00 น.  
                  </p>
                  <p>
                    หมายเหตุ
                    ทั้งนี้ผู้โชคดีที่เคยได้รับรางวัลไปแล้วสามารถได้รับรางวัลในการจับรางวัลในครั้งนี้  {" "}
                  </p>
                  <p>
                    4. ของรางวัลลุ้นโชครวมตลอดรายการ 6 รางวัล รวมมูลค่าทั้งสิ้น
                    289,800 บาท โดยของรางวัลมีดังนี้ รางวัลที่ 1)
                    ตั๋วเครื่องบินไปกลับ แพ็คเกจทัวร์ กรุงเกียวโต
                    และเยี่ยมชมไร่ชา ที่จังหวัดอูจิ ประเทศญี่ปุ่น 5 วัน 3 คืน
                    จำนวน 2 รางวัล รางวัลละ 2 ท่าน มูลค่ารางวัลละ 120,000 บาท
                    รวม 240,000 บาท รางวัลที่ 2) โทรศัพท์มือถือ iPhone 16 128 GB
                    จำนวน 1 รางวัล (คละสี) มูลค่ารางวัลละ 29,900 บาท รางวัลที่
                    3) เครื่องหนีบผม Dyson Airstrait™ straightener จำนวน 1
                    รางวัล (คละสี) มูลค่ารางวัลละ 19,900 บาท  {" "}
                  </p>

                  <h2>
                    รายละเอียด กติกา
                    และเงื่อนไขการร่วมกิจกรรมลุ้นโชครับของรางวัล {" "}
                  </h2>
                  <p>
                    1.สำหรับผู้โชคดีที่ได้รับรางวัลจากการลุ้นโชคจะต้องเป็นบุคคลธรรมดา
                    ไม่จำกัดสัญชาติ
                    มีภูมิลำเนาและถิ่นที่อยู่ปัจจุบันในประเทศไทยเท่านั้น
                    โดยผู้ที่ได้รับรางวัลจะได้รับการประกาศรายชื่อ ผ่านทาง
                    เฟซบุ๊กแฟนเพจ Lotte Koala’s March
                    (https://www.facebook.com/ilovekoalamarch)
                    ไลน์ออฟฟิเชียลแอคเคาน์ LINE Official Account: @koalas-march 
                    และต้องทำการติดต่อตัวแทนของบริษัทฯ
                    พร้อมนำส่งหลักฐานทางไปรษณีย์หรือช่องทางที่บริษัทกำหนดเพื่อยืนยันสิทธิ์ในการรับของรางวัลด้วยตนเอง
                    ได้แก่  {" "}
                  </p>
                  <p>
                    ก.)
                    สำเนาบัตรประจำตัวประชาชนที่ชื่อตรงกับที่ได้รับรางวัลหรือกรณีชาวต่างชาติสำเนาหนังสือเดินทางพาสปอร์ต
                    1 ชุด  เซ็นชื่อแล้วรับรองสำเนาถูกต้อง 
                  </p>
                  <p>
                    ข.) เอกสารยืนยันรับของรางวัล 1 ชุด
                    โดยผู้ที่ได้รับรางวัลส่งหลักฐานดังกล่าว
                    ในวันและเวลาทำการภายในระยะ 30 วัน
                    นับตั้งแต่วันประกาศรายชื่อผู้โชคดี เพื่อให้ทางบริษัทฯ
                    ดำเนินการจัดส่งของรางวัลต่อไป หากเลยกำหนดถือว่าสละสิทธิ์
                    และบริษัทฯ จะมอบของรางวัลให้กับรายชื่อสำรองลำดับถัดไป
                    ถ้ารายชื่อสำรองไม่มารับรางวัลตามระยะเวลาภายใน 7 วัน
                    ทางบริษัทฯ
                    จะมอบรางวัลดังกล่าวให้กับสภากาชาดไทยหรือสาธารณะกุศลอื่นต่อไป
                  </p>
                  <p>
                    2.บริษัทขอสงวนสิทธิ์ในการมอบรางวัลให้กับบุคคลผู้โชคดี 1 ชื่อ
                    ต่อ 1 รางวัล สำหรับการจับรางวัลแต่ละครั้งเท่านั้น
                    หากปรากฏชื่อผู้โชคดีท่านเดียวกันซ้ำกันอีก
                    คณะกรรมการในการจับรางวัลจะพิจารณายกเลิกรางวัลของชื่อผู้โชคดีท่านที่ซ้ำให้เหลือเพียงรางวัลเดียวและจับชิ้นส่วนเพื่อหาผู้โชคดีรายใหม่ต่อไป
                    และขอสงวนสิทธิ์ระงับมอบของรางวัล
                    หรือเรียกคืนของรางวัลในกรณีที่บริษัทฯ
                    ตรวจสอบพบว่าผู้มีสิทธิ์ได้รับรางวัล และ/หรือ
                    ผู้รับรางวัลรายใดรายหนึ่งมีคุณสมบัติไม่ถูกต้องครบถ้วน  {" "}
                  </p>
                  <p>
                    3.ระยะเวลาในการร่วมรายการคือผู้แลกคะแนน(พอยต์)
                    สำหรับของรางวัลประเภทลุ้นโชค เริ่มแลกได้ตั้งแต่วันที่ 1
                    กุมภาพันธ์ 2568 สิ้นสุดวันที่ 30 เมษายน 2568
                    โดยยึดถือเวลาที่ทำการแลกคะแนน จากระบบคอมพิวเตอร์ของบริษัทฯ {" "}
                  </p>
                  <p>
                    4.กรณีทางบริษัทฯ ตรวจสอบภายหลัง
                    และพบว่าผู้ได้รับรางวัลไม่อยู่ในเงื่อนไขที่กำหนด บริษัทฯ
                    สามารถยกเลิกสิทธิ์
                    และมอบสิทธิ์ใหม่ให้ผู้ได้รับรางวัลในรายชื่อสำรองได้
                    รวมถึงขอสงวนสิทธิ์ในการมอบ, ยกเลิก, เพิกถอน,
                    หรือเรียกคืนรางวัลทั้งหมด รวมทั้งเรียกให้ชดใช้ค่าเสียหาย
                    หากมีการใช้สิทธิร่วมรายการโดยทุจริต, ปลอมแปลง, ฉ้อฉล,
                    เพื่อให้ได้มาซึ่งสิทธิ์ในรายการนี้ 
                  </p>
                  <p>
                    5.ผู้โชคดีที่ได้รับรางวัลลุ้นโชคมูลค่า 1,000 บาท ขึ้นไป
                    จะต้องชำระภาษี ณ ที่จ่าย 5% ตามคำสั่งกรมสรรพากร ที่ ท.ป.
                    4/2528 ลงวันที่ 26 กันยายน 2528 ประกอบคำสั่งกรมสรรพากร ท.ป.
                    104/2554 ลงวันที่ 15 กันยายน 2544 โดยที่บริษัทฯ
                    ไม่รับผิดชอบในการชำระภาษีรายได้บุคคลประจำปี
                  </p>
                  <p>
                    6.ทางบริษัทจะจัดส่งของรางวัลให้ผู้โชคดี ภายใน 60
                    วันนับจากวันประกาศรายชื่อผู้โชคดีในแต่ละครั้ง
                    และทางผู้โชคดีจะต้องรับผิดชอบค่าใช้จ่ายอื่นใด ๆ
                    ที่เกี่ยวข้องกับการรับของรางวัล
                    และการเข้าร่วมกิจกรรมนี้ด้วยตนเอง 
                  </p>
                  <p>
                    7.กรณีมีข้อพิพาทขัดแย้ง หรือต้องตีความ
                    ให้ยึดตามคำตัดสินของคณะกรรมการซึ่งถือเป็นเด็ดขาดและสิ้นสุดในทุกกรณี 
                  </p>
                  <p>
                    8.พนักงานและครอบครัวของ บริษัท ไทยลอตเต้ จำกัด(“บริษัทฯ”),
                    บริษัท วินเทอร์อีเจนซี่ จำกัด, เอเจนซี่
                    รวมทั้งบุคคลหรือองค์กรที่เกี่ยวข้องกับการจัดรายการในครั้งนี้ไม่มีสิทธิ์เข้าร่วมรายการ 
                  </p>
                  <p>
                    9.ใบอนุญาตเลขที่ 618/2567 ออก ณ ที่ว่าการอำเภอ ปากเกร็ด
                    จังหวัดนนทบุรี
                  </p>
                  <h2>  เงื่อนไขการร่วมกิจกรรมแลกรับของรางวัล  </h2>
                  <p>
                    1. รายละเอียดของรางวัลสำหรับการใช้พอยต์แลกของรางวัล มีดังนี้
                    *ของรางวัลมีจำนวนจำกัด    {" "}
                  </p>
                  <p>
                    1.1 แก้วน้ำโคอะลา มาร์ช มูลค่า 199 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 15 พอยต์ (จำนวนจำกัด 720 สิทธิ์){" "}
                  </p>
                  <p>
                    1.2 กระเป๋าคล้องคอโคอะลา มาร์ช มูลค่า 299 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 20 พอยต์ (จำนวนจำกัด 240 สิทธิ์)
                  </p>
                  <p>
                    1.3 กระเป๋านักเรียนโคอะลา มาร์ช มูลค่า 399 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 25 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.4 ตุ๊กตาโคอะลา มาร์ช มูลค่า 599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.5 รองเท้าสลิปเปอร์โคอะลา มาร์ช มูลค่า 599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 400 สิทธิ์)
                  </p>
                  <p>
                    1.6 หมอนรองคอโคอะลา มาร์ช มูลค่า 899 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 25 พอยต์ (จำนวนจำกัด 10 สิทธิ์)
                  </p>
                  <p>
                    1.7 รองเท้าสลิปเปอร์โคอะลา มาร์ช มูลค่า 599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.8 ตุ๊กตาแองเจิลโคอะลา มาร์ช มูลค่า 699 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.9 ตุ๊กตาเดวิลล์โคอะลา มาร์ช มูลค่า 699 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.10 ตุ๊กตาผึ้งโคอะลา มาร์ช มูลค่า 699 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.11 กระเป๋ารถเข็นพับได้โคอะลา มาร์ช มูลค่า 1,399 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 45 พอยต์ (จำนวนจำกัด 150 สิทธิ์)
                  </p>
                  <p>
                    1.12 กระเป๋าเดินทางโคอะลา มาร์ชอูจิมัทฉะ มูลค่า 1,599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 75 พอยต์ (จำนวนจำกัด 100 สิทธิ์)
                  </p>
                  <p>
                    1.13 Let’s Relax voucher มูลค่า 500 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 50 พอยต์ (จำนวนจำกัด 50 สิทธิ์)
                  </p>
                  <p>
                    1.14 แก้วน้ำโคอะลา มาร์ช ลายครบครอบ 40 ปี มูลค่า 199 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 15 พอยต์ (จำนวนจำกัด 83 สิทธิ์)
                  </p>
                  <p>
                    หมายเหตุ: พอยต์ที่ใช้แลกของรางวัลและแลกสิทธิ์ลุ้นโชคไปแล้ว
                    จะถูกลบออกจากพอยต์สะสม เช่น มีพอยต์สะสม 100 พอยต์
                    แลกของรางวัล 50 พอยต์ จะทำให้พอยต์สะสมคงเหลือสุทธิ 50 พอยต์
                    ของรางวัลจะถูกจัดส่งภายใน 60 วันหลังจากที่ท่านแลกของรางวัล
                  </p>
                  <h2>
                    รายละเอียด กติกา
                    และเงื่อนไขการร่วมกิจกรรมและแลกพอยต์รับของรางวัล 
                  </h2>
                  <p>
                    1.
                    สำหรับผู้ร่วมกิจกรรมที่แลกพอยต์รับรางวัลจะต้องเป็นบุคคลธรรมดา
                    ไม่จำกัดสัญชาติ
                    มีภูมิลำเนาและถิ่นที่อยู่ปัจจุบันในประเทศไทยเท่านั้น
                  </p>
                  <p>
                    2. หลังจากผู้เข้าร่วมกิจกรรมกดแลกรับของรางวัลแล้ว
                    ของรางวัลจะถูกจัดส่งภายใน 60 วันหลังจากที่กดแลกรับของรางวัล
                    โดยเริ่มนับวันที่ 1 ในวันถัดไป (*ของรางวัลมีจำนวนจำกัด)
                    ท่านสามารถดูจำนวนของรางวัลคงเหลือผ่านเมนู “แลกของรางวัล” 
                  </p>
                  <p>
                    3. ของรางวัลไม่สามารถแลก ทอน หรือเปลี่ยนเป็นเงินสดได้
                    และไม่สามารถโอนสิทธิ์ให้ผู้อื่นได้ บริษัทฯ
                    ขอสงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไข เพิ่ม
                    หรือลดของรางวัลและเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า 
                  </p>
                  <p>
                    4. หากบริษัทฯ
                    ตรวจสอบภายหลังและพบว่าผู้ได้รับรางวัลไม่อยู่ในเงื่อนไขที่กำหนดหรือทำผิดกติกาไม่ว่าส่วนใดส่วนหนึ่ง
                    บริษัทฯ สามารถยกเลิกสิทธิ์
                    และมอบสิทธิ์ใหม่ให้ผู้ได้รับรางวัลในรายชื่อสำรองได้
                    รวมถึงขอสงวนสิทธิ์ในการมอบ, ยกเลิก, เพิกถอน,
                    หรือเรียกคืนรางวัลทั้งหมด รวมทั้งเรียกให้ชดใช้ค่าเสียหาย
                    หากมีการใช้สิทธิ์ร่วมรายการโดยทุจริต, ปลอมแปลง, ฉ้อฉล
                    เพื่อให้ได้มาซึ่งสิทธิ์ในรายการนี้โดยมิชอบ 
                  </p>
                  <p>
                    5. หากข้อมูลในระบบสูญหายอันเนื่องมาจากเหตุการณ์ไม่คาดคิดใด ๆ
                    เช่น
                    ภัยธรรมชาติการเกิดสถานการณ์ความไม่สงบหรือการโจรกรรมทางคอมพิวเตอร์
                    ฯลฯ ทางบริษัทฯ จะไม่รับผิดชอบข้อมูลส่วนที่สูญหายนั้น
                    และหากมีเหตุจำเป็นต้องขอข้อมูลใหม่ในช่วงที่กิจกรรมยังไม่สิ้นสุดทางบริษัทฯ
                    จะแจ้งขอข้อมูลของท่านอีกครั้ง
                  </p>
                  <p>
                    6. พนักงานและครอบครัวของบริษัท ไทยลอตเต้ จำกัด,
                    ตัวแทนบริษัท, พนักงานร้านค้าตัวแทนจำหน่าย, เอเจนซี่,
                    คณะกรรมการดำเนินงาน
                    รวมถึงบริษัทที่เกี่ยวข้องกับการจัดกิจกรรม
                    ไม่มีสิทธิเข้าร่วมหรือรับของรางวัลในกิจกรรมนี้
                  </p>
                  <p>
                    7. กรณีมีข้อขัดแย้งหรือข้อพิพาท
                    คำตัดสินของคณะกรรมการถือเป็นเด็ดขาดและสิ้นสุด 
                  </p>
                  <p>
                    8. รูปภาพในกิจกรรมทั้งหมดใช้เพื่อประกอบการโฆษณาเท่านั้น 
                  </p>
                  <p>
                    9.
                    บริษัทขอสงวนสิทธิ์ในการปรับเปลี่ยนอัตราหรือจำนวนพอยต์ที่ผู้ใช้งานจะได้รับจากการพิมพ์รหัสในกล่อง
                    Koala’s March และการร่วมกิจกรรมต่าง ๆ
                    ได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า  
                  </p>
                  <p>
                    10.
                    ผู้ร่วมกิจกรรมเข้าใจและรับทราบเป็นอย่างดีถึงวัตถุประสงค์ของกิจกรรมนี้และตกลงยินยอมที่จะให้บริษัทฯ
                    ทำการเก็บรวบรวมส่วนบุคคลที่มีความอ่อนไหว
                    ใช้ข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรม
                    เฉพาะที่จำเป็นสำหรับการร่วมกิจกรรมเป็นไปตามนโยบายของบริษัทฯ
                    และตามข้อบังคับของกฎหมาย และ/หรือเพื่อให้การเข้าร่วมกิจกรรม
                    การแจ้งผล และการรับรางวัลในกิจกรรม
                    ตลอดจนการนำข้อมูลดังกล่าวมาประมวลผลของกิจกรรมนี้ว่าบรรลุวัตถุประสงค์สมบูรณ์อย่างแท้จริง
                    ทั้งนี้ท่านควรตรวจสอบรายละเอียดกติกาและเงื่อนไขนี้เป็นระยะเพื่อรับทราบการเปลี่ยนแปลงที่อาจเกิดขึ้น
                    การที่ท่านเข้ามาใช้งานระบบหมายความว่าท่านยอมรับการเปลี่ยนแปลงนั้น 
                  </p>
                  <p>
                    11. ทางบริษัทฯ สงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไข
                    และของรางวัลโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                    กรณีมีเหตุภัยธรรมชาติ หรืออื่นใด อีกทั้งบริษัทฯ
                    จะไม่รับผิดชอบต่อคุณภาพที่อาจเกิดขึ้นกับรางวัลดังกล่าว
                    หากมีปัญหาเกี่ยวกับคุณภาพ หรือบริการ
                    ผู้ร่วมกิจกรรมจะต้องติดต่อบริษัทที่จัดจำหน่ายอย่างเป็นทางการโดยตรง 
                  </p>
                  <p>
                    12.
                    การขอเปลี่ยนของรางวัลเนื่องจากของรางวัลชำรุดและเสียหายจากการขนส่ง
                    รวมถึงได้รับของรางวัลผิดประเภทจากที่ตนได้ทำการแลกไป
                    ทางบริษัทฯ
                    ขอสงวนสิทธิ์ในการเปลี่ยนของรางวัลในกรณีดังกล่าวได้ไม่เกิน 2
                    ครั้ง
                    โดยจำเป็นต้องส่งของรางวัลที่ชำรุดหรือเสียหายกลับมาให้ทางบริษัทฯ
                    เป็นที่เรียบร้อยก่อน หลังจากนั้นทางบริษัทฯ
                    จึงจะจัดส่งของรางวัลชิ้นใหม่ไปให้
                    และต้องแจ้งขอเปลี่ยนของรางวัลภายใน 15
                    วันทำการหลังจากที่ของรางวัลได้ถูกจัดส่งสำเร็จ
                  </p>
                  <p>
                    13. ของรางวัลมีจำนวนจำกัด
                    ท่านสามารถตรวจสอบจำนวนคงเหลือผ่านริชเมนูหัวข้อ
                    “แลกของรางวัล” 
                  </p>
                  <p>
                    14. ติดตามกิจกรรมพิเศษและศึกษารายละเอียดเพิ่มเติมได้ที่ LINE
                    OA @koalas-march {" "}
                  </p>
                  <p>
                    ข้าพเจ้าได้อ่านและเข้าใจ กติกาและเงื่อนไขนี้เป็นอย่างดีแล้ว
                    และให้ความยินยอมให้ข้อมูลแก่บริษัทฯ
                    ในการใช้ข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อส่งข้อมูลกิจกรรมส่งเสริมการขายและการตลาดต่าง
                    ๆ ที่บริษัทฯ ต้องการจะสื่อสารในแคมเปญต่อ ๆ ไป {" "}
                  </p>
                </div>
              </div>
              {/* กิจกรรมที่ 2 */}
              {/* <div className="bContent bCard">
                <h1>ข้อกำหนดกติกาและเงื่อนไข</h1>
                <h1 style={{ marginBottom: "10px" }}>
                  กิจกรรมครั้งที่ 2 - ระยะเวลา
                </h1>
                <h1>1 พฤษภาคม 2567 – 31 ธันวาคม 2567</h1>

                <div className="bDetails">
                  <p>
                    บริษัท ไทยลอตเต้ จำกัด ("เรา" หรือ "บริษัทฯ")
                    ขอแจ้งข้อกำหนดและเงื่อนไขก่อนร่วมรายการ โดยเนื้อหาทั้งหมดนี้
                    ซึ่งรวมถึงเอกสารหรือข้อความต่าง ๆ
                    ที่เกี่ยวข้องกับเนื้อหาดังกล่าว
                    ระบุไว้เพื่อแจ้งให้ท่านทราบถึงข้อกำหนดและเงื่อนไขต่าง ๆ
                    (ซึ่งต่อไปนี้จะเรียกว่า "ข้อกำหนดกติกาและเงื่อนไข")
                    ในการลงทะเบียนกิจกรรม "Lucky Koala’s March สนุก แลก ลุ้น
                    รางวัลตลอดปี" ("กิจกรรม") เพื่อให้มีสิทธิแลกรับของรางวัล
                    และลุ้นโชครับรางวัล โดยมีวิธีการดังนี้{" "}
                  </p>
                  <h2>ขั้นตอนที่หนึ่ง</h2>
                  <ul>
                    <li>
                      ซื้อโคอะลา มาร์ช ทุกขนาดและทุกรสชาติ (ยกเว้นโคอะลา
                      มาร์ชขนาด 5 บาท 10 บาทและรสมะม่วงและสัปปะรด)
                      ที่ร้านค้าชั้นนำทั่วประเทศ
                    </li>
                    <li>
                      และทำการแอดไลน์ทางการ @koalas-march
                      ทำการลงทะเบียนบนลิงก์ในริชเมนูพร้อมกรอกรายละเอียด
                      ชื่อ-นามสกุล เบอร์โทรศัพท์ โดย 1 บัญชีไลน์
                      สามารถลงทะเบียนได้เพียง 1 ครั้งเท่านั้น{" "}
                    </li>
                    <li>
                      และหลังจากนั้นผู้ร่วมกิจกรรมต้องกรอกรหัสในกล่องจำนวน 13
                      หลักหรือ 14 หลักส่งเข้าระบบผ่านริชเมนูหัวข้อ กรอกรหัส โดย
                      1 รหัสของขนาด 33 กรัม และ 37 กรัม มีค่าเท่ากับ 1 พอยต์ และ
                      1 รหัสของขนาด 195 กรัม มีค่าเท่ากับ 5 พอยต์ สามารถซื้อ
                      และส่งรหัสในกล่องได้ตั้งแต่วันที่ 1 พ.ค. พ.ศ. 2567
                      และจะสิ้นสุดลงในวันที่ 31 ธ.ค. พ.ศ. 2567
                    </li>
                    <li>
                      สำหรับผู้ที่ทำการแอดไลน์ทางการ @koalas-march
                      ทำการลงทะเบียนในวันที่ 30 เมษายน 2567 ถึง 19 พฤษภาคม 2567
                      รับทันทีพอยต์ extra point จำนวน 5 พอยต์
                    </li>
                  </ul>
                  <h2>ขั้นตอนที่สอง</h2>
                  <p>
                    กดแลกรับของรางวัลได้ตามพอยต์ที่มี
                    หรือแลกพอยต์รับสิทธิ์ลุ้นโชครางวัลใหญ่ ได้ง่ายๆ โดย 1 พอยต์
                    รับ 1 สิทธิ์ลุ้นโชค โดยมีการจับรางวัล 1 ครั้ง
                  </p>
                  <h2>รายละเอียดเพิ่มเติมของรางวัลลุ้นโชค</h2>
                  <p>
                    เริ่มแลกได้ตั้งแต่วันที่ 1 พฤษภาคม 2567 ถึง วันที่ 31
                    ธันวาคม 2567
                  </p>
                  <p>
                    1. สมาชิกโคอะลา มาร์ช อูจิมัทฉะ ที่มีคะแนนสะสม
                    สามารถแลกคะแนนสิทธิ์สำหรับลุ้นชิงโชค
                    โดยมีอัตราการแลกคะแนนคือ ทุก 1 พอยต์ รับ 1 สิทธิ์
                  </p>
                  <p>
                    2. สมาชิกโคอะลา มาร์ช อูจิมัทฉะ
                    สามารถแลกคะแนนได้ไม่จำกัดจำนวนสิทธิ์
                    ตามคะแนนสะสมที่คงเหลือของสมาชิก ณ วันที่สมาชิกทำการแลกคะแนน
                    และการแลกคะแนนเพื่อรับสิทธิ์ลุ้นโชคจะต้องทำผ่านระบบสะสมคะแนนบนไลน์ทางการ
                    @koalas-march เท่านั้น
                  </p>
                  <p>
                    เมื่อครบกำหนดระยะเวลา ทางบริษัทฯ
                    จะทำการรวบรวมรายชื่อของผู้เข้าร่วมรายการที่แลกคะแนนถูกต้อง
                    โดยทำการพิมพ์รายชื่อ- นามสกุล และวันที่ทำการแลกคะแนน
                    ของทุกคนลงบนกระดาษตัดเป็นชิ้นส่วนตามจำนวนสิทธิ์
                    และนำมาจับรางวัล ณ ห้องสัมมนา บริษัท วินเทอร์อีเจนซี่ จำกัด
                    อาคาร 140 ไวร์เลส ชั้นที่ 17 เลขที่ 140 ถนนวิทยุ แขวงลุมพินี
                    เขตปทุมวัน กรุงเทพมหานคร 10330{" "}
                  </p>
                  <p>
                    บริษัทฯ จะประกาศรายชื่อผู้โชคดีภายใน 7 วัน
                    ตามลำดับการจับรางวัลในแต่ละครั้ง ผ่านทาง เฟซบุ๊กแฟนเพจ Lotte
                    Koala’s March (https://www.facebook.com/ilovekoalamarch)
                    ไลน์ออฟฟิเชียลแอคเคาน์ LINE Official Account: @koalas-march{" "}
                  </p>
                  <p>
                    3. มีการจับรางวัล 1 ครั้ง ดังนี้
                    <br />
                    ครั้งที่ 1 เริ่มวันที่ 1 พฤษภาคม 2567 สิ้นสุดวันที่ 31
                    ธันวาคม 2567 กำหนดจับรางวัลวันที่ 31 มกราคม 2568 เวลา 11.00
                    น.
                  </p>
                  <p>
                    <b>
                      <u>หมายเหตุ</u>
                    </b>{" "}
                    ทั้งนี้ผู้โชคดีที่เคยได้รับรางวัลไปแล้วสามารถได้รับรางวัลในการจับรางวัลในครั้งที่
                    2
                  </p>
                  <p>
                    4. ของรางวัลลุ้นโชครวมตลอดรายการ 7 รางวัล รวมมูลค่าทั้งสิ้น
                    508,300 บาท โดยของรางวัลครั้งที่ 1 มีดังนี้
                    <br />
                    <b>
                      <u>ครั้งที่ 1</u>
                    </b>
                    รางวัลที่ 1) ตั๋วเครื่องบินไปกลับ แพ็คเกจทัวร์ กรุงโตเกียว
                    ประเทศญี่ปุ่น 5 วัน 3 คืน จำนวน 2 รางวัล รางวัลละ 4 ท่าน
                    มูลค่ารางวัลละ 200,000 บาท รวม 400,000 บาท
                    <br />
                    รางวัลที่ 2) บัตรกำนันที่พักโรงแรมแกรนด์ เซนเตอร์ พอยต์ สเปซ
                    พัทยา (ห้อง space connex) 1 คืน จำนวน 2 รางวัล รางวัลละ 4
                    ท่าน มูลค่ารางวัลละ 12,300 บาท รวม 24,600 บาท
                    <br />
                    รางวัลที่ 3) โทรศัพท์มือถือ IPhone 15 128 GB จำนวน 2 รางวัล
                    (คละสี) มูลค่ารางวัลละ 32,900 บาท รวม 65,800 บาท
                    <br />
                    รางวัลที่ 4) ไดร์เป่าผม Dyson Supersonic จำนวน 1 รางวัล
                    มูลค่า 17,900 บาท
                  </p>
                  <h2>
                    รายละเอียด กติกา
                    และเงื่อนไขการร่วมกิจกรรมลุ้นโชครับของรางวัล
                  </h2>
                  <p>
                    1.สำหรับผู้โชคดีที่ได้รับรางวัลจากการลุ้นโชคจะต้องเป็นบุคคลธรรมดา
                    ไม่จำกัดสัญชาติ
                    มีภูมิลำเนาและถิ่นที่อยู่ปัจจุบันในประเทศไทยเท่านั้น
                    โดยผู้ที่ได้รับรางวัลจะได้รับการประกาศรายชื่อ ผ่านทาง
                    เฟซบุ๊กแฟนเพจ Lotte Koala’s March
                    (https://www.facebook.com/ilovekoalamarch)
                    ไลน์ออฟฟิเชียลแอคเคาน์ LINE Official Account: @koalas-march
                    และต้องทำการติดต่อตัวแทนของบริษัทฯ
                    พร้อมนำส่งหลักฐานทางไปรษณีย์หรือช่องทางที่บริษัทกำหนดเพื่อยืนยันสิทธิ์ในการรับของรางวัลด้วยตนเอง
                    ได้แก่
                  </p>
                  <p>
                    ก.)
                    สำเนาบัตรประจำตัวประชาชนที่ชื่อตรงกับที่ได้รับรางวัลหรือกรณีชาวต่างชาติสำเนาหนังสือเดินทางพาสปอร์ต
                    1 ชุด เซ็นชื่อแล้วรับรองสำเนาถูกต้อง
                  </p>
                  <p>
                    ข.) เอกสารยืนยันรับของรางวัล 1 ชุด
                    โดยผู้ที่ได้รับรางวัลส่งหลักฐานดังกล่าว
                    ในวันและเวลาทำการภายในระยะ 30 วัน
                    นับตั้งแต่วันประกาศรายชื่อผู้โชคดี เพื่อให้ทางบริษัทฯ
                    ดำเนินการจัดส่งของรางวัลต่อไป หากเลยกำหนดถือว่าสละสิทธิ์
                    และบริษัทฯ จะมอบของรางวัลให้กับรายชื่อสำรองลำดับถัดไป
                    ถ้ารายชื่อสำรองไม่มารับรางวัลตามระยะเวลาภายใน 7 วัน
                    ทางบริษัทฯ
                    จะมอบรางวัลดังกล่าวให้กับสภากาชาดไทยหรือสาธารณะกุศลอื่นต่อไป
                  </p>
                  <p>
                    2.บริษัทขอสงวนสิทธิ์ในการมอบรางวัลให้กับบุคคลผู้โชคดี 1 ชื่อ
                    ต่อ 1 รางวัล สำหรับการจับรางวัลแต่ละครั้งเท่านั้น
                    หากปรากฏชื่อผู้โชคดีท่านเดียวกันซ้ำกันอีก
                    คณะกรรมการในการจับรางวัลจะพิจารณายกเลิกรางวัลของชื่อผู้โชคดีท่านที่ซ้ำให้เหลือเพียงรางวัลเดียวและจับชิ้นส่วนเพื่อหาผู้โชคดีรายใหม่ต่อไป
                    และขอสงวนสิทธิ์ระงับมอบของรางวัล
                    หรือเรียกคืนของรางวัลในกรณีที่บริษัทฯ
                    ตรวจสอบพบว่าผู้มีสิทธิ์ได้รับรางวัล และ/หรือ
                    ผู้รับรางวัลรายใดรายหนึ่งมีคุณสมบัติไม่ถูกต้องครบถ้วน
                  </p>
                  <p>
                    3.ระยะเวลาในการร่วมรายการคือผู้แลกคะแนน(พอยต์)
                    สำหรับของรางวัลประเภทลุ้นโชค เริ่มแลกได้ตั้งแต่วันที่ 1
                    พฤษภาคม 2567 สิ้นสุดวันที่ 31 ธันวาคม 2567
                    โดยยึดถือเวลาที่ทำการแลกคะแนน จากระบบคอมพิวเตอร์ของบริษัทฯ
                  </p>
                  <p>
                    4.กรณีทางบริษัทฯ ตรวจสอบภายหลัง
                    และพบว่าผู้ได้รับรางวัลไม่อยู่ในเงื่อนไขที่กำหนด บริษัทฯ
                    สามารถยกเลิกสิทธิ์
                    และมอบสิทธิ์ใหม่ให้ผู้ได้รับรางวัลในรายชื่อสำรองได้
                    รวมถึงขอสงวนสิทธิ์ในการมอบ, ยกเลิก, เพิกถอน,
                    หรือเรียกคืนรางวัลทั้งหมด รวมทั้งเรียกให้ชดใช้ค่าเสียหาย
                    หากมีการใช้สิทธิร่วมรายการโดยทุจริต, ปลอมแปลง, ฉ้อฉล,
                    เพื่อให้ได้มาซึ่งสิทธิในรายการนี้
                  </p>
                  <p>
                    5.ผู้โชคดีที่ได้รับรางวัลลุ้นโชคมูลค่า 1,000 บาท ขึ้นไป
                    จะต้องชำระภาษี ณ ที่จ่าย 5% ตามคำสั่งกรมสรรพากร ที่ ท.ป.
                    4/2528 ลงวันที่ 26 กันยายน 2528 ประกอบคำสั่งกรมสรรพากร ท.ป.
                    104/2554 ลงวันที่ 15 กันยายน 2544 โดยที่บริษัทฯ
                    ไม่รับผิดชอบในการชำระภาษีรายได้บุคคลประจำปี
                  </p>
                  <p>
                    6.ทางบริษัทจะจัดส่งของรางวัลให้ผู้โชคดี ภายใน 30
                    วันนับจากวันประกาศรายชื่อผู้โชคดีในแต่ละครั้ง
                    และทางผู้โชคดีจะต้องรับผิดชอบค่าใช้จ่ายอื่นใด ๆ
                    ที่เกี่ยวข้องกับการรับของรางวัล
                    และการเข้าร่วมกิจกรรมนี้ด้วยตนเอง
                  </p>
                  <p>
                    7.กรณีมีข้อพิพาทขัดแย้ง หรือต้องตีความ
                    ให้ยึดตามคำตัดสินของคณะกรรมการซึ่งถือเป็นเด็ดขาดและสิ้นสุดในทุกกรณี
                  </p>
                  <p>
                    8.พนักงานและครอบครัวของ บริษัท ไทยลอตเต้ จำกัด(“บริษัทฯ”),
                    บริษัท วินเทอร์อีเจนซี่ จำกัด, เอเจนซี่
                    รวมทั้งบุคคลหรือองค์กรที่เกี่ยวข้องกับการจัดรายการในครั้งนี้ไม่มีสิทธิ์เข้าร่วมรายการ
                  </p>
                  <p>
                    9. ใบอนุญาตเลขที่ 13/2567 ออก ณ ที่ว่าการอำเภอ ปากเกร็ด
                    จังหวัดนนทบุรี
                  </p>
                  <h2>
                    รายละเอียด กติกา และเงื่อนไขการร่วมกิจกรรมแลกรับของรางวัล
                  </h2>
                  <p>
                    1. รายละเอียดของรางวัลสำหรับการใช้พอยต์แลกของรางวัล มีดังนี้
                    *ของรางวัลมีจำนวนจำกัด 1.1 กระเป๋าผ้าโคอะลา มาร์ช มูลค่า 299
                    บาท สามารถแลกได้โดยใช้พอยต์สะสม 15 พอยต์ (จำนวนจำกัด 210
                    สิทธิ์)
                  </p>
                  <p>
                    1.2 แก้วน้ำเก็บความเย็นโคอะลา มาร์ช อูจิมัทฉะ มูลค่า 219 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 20 พอยต์ (จำนวนจำกัด 250 สิทธิ์)
                  </p>
                  <p>
                    1.3 แก้วน้ำเก็บความเย็นโคอะลา มาร์ช มูลค่า 199 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 15 พอยต์ (จำนวนจำกัด 500 สิทธิ์)
                  </p>
                  <p>
                    1.4 กระเป๋าคล้องคอโคอะลา มาร์ช มูลค่า 299 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 20 พอยต์ (จำนวนจำกัด 340 สิทธิ์)
                  </p>
                  <p>
                    1.5 กระเป๋านักเรียนโคอะลา มาร์ช มูลค่า 399 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 25 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.6 ตุ๊กตาโคอะลา มาร์ช มูลค่า 599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 500 สิทธิ์)
                  </p>
                  <p>
                    1.7 รองเท้าสลิปเปอร์โคอะลา มาร์ช มูลค่า 599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 30 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.8 หมอนรองคอโคอะลา มาร์ช มูลค่า 899 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 25 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>
                  <p>
                    1.9 ตุ๊กตาแองเจิลโคอะลา มาร์ช มูลค่า 699 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.10 ตุ๊กตาเดวิลล์โคอะลา มาร์ช มูลค่า 699 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.11 ตุ๊กตาผึ้งโคอะลา มาร์ช มูลค่า 699 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 40 พอยต์ (จำนวนจำกัด 300 สิทธิ์)
                  </p>
                  <p>
                    1.12 กระเป๋ารถเข็นพับได้โคอะลา มาร์ช มูลค่า 1,399 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 45 พอยต์ (จำนวนจำกัด 220 สิทธิ์)
                  </p>
                  <p>
                    1.13 กระเป๋าเดินทางโคอะลา มาร์ชอูจิมัทฉะ มูลค่า 1,599 บาท
                    สามารถแลกได้โดยใช้พอยต์สะสม 75 พอยต์ (จำนวนจำกัด 200 สิทธิ์)
                  </p>

                  <p>
                    <b>
                      <u>หมายเหตุ:</u>
                    </b>{" "}
                    พอยต์ที่ใช้แลกของรางวัลและแลกสิทธิ์ลุ้นโชคไปแล้ว
                    จะถูกลบออกจากพอยต์สะสม เช่น มีพอยต์สะสม 100 พอยต์
                    แลกของรางวัล 50 พอยต์ จะทำให้พอยต์สะสมคงเหลือสุทธิ 50 พอยต์
                    ของรางวัลจะถูกจัดส่งภายใน 30 วันหลังจากที่ท่านแลกของรางวัล
                  </p>
                  <h2>
                    รายละเอียด กติกา
                    และเงื่อนไขการร่วมกิจกรรมและแลกพอยต์รับของรางวัล
                  </h2>
                  <p>
                    1.
                    สำหรับผู้ร่วมกิจกรรมที่แลกพอยต์รับรางวัลจะต้องเป็นบุคคลธรรมดา
                    ไม่จำกัดสัญชาติ
                    มีภูมิลำเนาและถิ่นที่อยู่ปัจจุบันในประเทศไทยเท่านั้น
                  </p>
                  <p>
                    2. หลังจากผู้เข้าร่วมกิจกรรมกดแลกรับของรางวัลแล้ว
                    ของรางวัลจะถูกจัดส่งภายใน 30 วันหลังจากที่กดแลกรับของรางวัล
                    โดยเริ่มนับวันที่ 1 ในวันถัดไป (*ของรางวัลมีจำนวนจำกัด)
                    ท่านสามารถดูจำนวนของรางวัลคงเหลือผ่านเมนู “แลกของรางวัล”
                  </p>
                  <p>
                    3. ของรางวัลไม่สามารถแลก ทอน หรือเปลี่ยนเป็นเงินสดได้
                    และไม่สามารถโอนสิทธิ์ให้ผู้อื่นได้ บริษัทฯ
                    ขอสงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไข เพิ่ม
                    หรือลดของรางวัลและเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                  </p>
                  <p>
                    4. หากบริษัทฯ
                    ตรวจสอบภายหลังและพบว่าผู้ได้รับรางวัลไม่อยู่ในเงื่อนไขที่กำหนดหรือทำผิดกติกาไม่ว่าส่วนใดส่วนหนึ่ง
                    บริษัทฯ สามารถยกเลิกสิทธิ์
                    และมอบสิทธิ์ใหม่ให้ผู้ได้รับรางวัลในรายชื่อสำรองได้
                    รวมถึงขอสงวนสิทธิ์ในการมอบ, ยกเลิก, เพิกถอน,
                    หรือเรียกคืนรางวัลทั้งหมด รวมทั้งเรียกให้ชดใช้ค่าเสียหาย
                    หากมีการใช้สิทธิ์ร่วมรายการโดยทุจริต, ปลอมแปลง, ฉ้อฉล
                    เพื่อให้ได้มาซึ่งสิทธิ์ในรายการนี้โดยมิชอบ
                  </p>
                  <p>
                    5. หากข้อมูลในระบบสูญหายอันเนื่องมาจากเหตุการณ์ไม่คาดคิดใด ๆ
                    เช่น
                    ภัยธรรมชาติการเกิดสถานการณ์ความไม่สงบหรือการโจรกรรมทางคอมพิวเตอร์
                    ฯลฯ ทางบริษัทฯ จะไม่รับผิดชอบข้อมูลส่วนที่สูญหายนั้น
                    และหากมีเหตุจำเป็นต้องขอข้อมูลใหม่ในช่วงที่กิจกรรมยังไม่สิ้นสุดทางบริษัทฯ
                    จะแจ้งขอข้อมูลของท่านอีกครั้ง
                  </p>
                  <p>
                    6. พนักงานและครอบครัวของบริษัท ไทยลอตเต้ จำกัด,
                    ตัวแทนบริษัท, พนักงานร้านค้าตัวแทนจำหน่าย, เอเจนซี่,
                    คณะกรรมการดำเนินงาน
                    รวมถึงบริษัทที่เกี่ยวข้องกับการจัดกิจกรรม
                    ไม่มีสิทธิเข้าร่วมหรือรับของรางวัลในกิจกรรมนี้
                  </p>
                  <p>
                    7. กรณีมีข้อขัดแย้งหรือข้อพิพาท
                    คำตัดสินของคณะกรรมการถือเป็นเด็ดขาดและสิ้นสุด
                  </p>
                  <p>8. รูปภาพในกิจกรรมทั้งหมดใช้เพื่อประกอบการโฆษณาเท่านั้น</p>
                  <p>
                    9.
                    บริษัทขอสงวนสิทธิ์ในการปรับเปลี่ยนอัตราหรือจำนวนพอยต์ที่ผู้ใช้งานจะได้รับจากการพิมพ์รหัสในกล่อง
                    Koala’s March และการร่วมกิจกรรมต่างๆ
                    ได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า{" "}
                  </p>
                  <p>
                    10.
                    ผู้ร่วมกิจกรรมเข้าใจและรับทราบเป็นอย่างดีถึงวัตถุประสงค์ของกิจกรรมนี้และตกลงยินยอมที่จะให้บริษัทฯ
                    ทำการเก็บรวบรวมส่วนบุคคลที่มีความอ่อนไหว
                    ใช้ข้อมูลส่วนบุคคลของผู้ร่วมกิจกรรม
                    เฉพาะที่จำเป็นสำหรับการร่วมกิจกรรมเป็นไปตามนโยบายของบริษัทฯ
                    และตามข้อบังคับของกฎหมาย และ/หรือเพื่อให้การเข้าร่วมกิจกรรม
                    การแจ้งผล และการรับรางวัลในกิจกรรม
                    ตลอดจนการนำข้อมูลดังกล่าวมาประมวลผลของกิจกรรมนี้ว่าบรรลุวัตถุประสงค์สมบูรณ์อย่างแท้จริง
                    ทั้งนี้ท่านควรตรวจสอบรายละเอียดกติกาและเงื่อนไขนี้เป็นระยะเพื่อรับทราบการเปลี่ยนแปลงที่อาจเกิดขึ้น
                    การที่ท่านเข้ามาใช้งานระบบหมายความว่าท่านยอมรับการเปลี่ยนแปลงนั้น
                  </p>
                  <p>
                    11. ทางบริษัทฯ สงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไข
                    และของรางวัลโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                    กรณีมีเหตุภัยธรรมชาติ หรืออื่นใด อีกทั้งบริษัทฯ
                    จะไม่รับผิดชอบต่อคุณภาพที่อาจเกิดขึ้นกับรางวัลดังกล่าว
                    หากมีปัญหาเกี่ยวกับคุณภาพ หรือบริการ
                    ผู้ร่วมกิจกรรมจะต้องติดต่อบริษัทที่จัดจำหน่ายอย่างเป็นทางการโดยตรง
                  </p>
                  <p>
                    12. ของรางวัลมีจำนวนจำกัด
                    ท่านสามารถตรวจสอบจำนวนคงเหลือผ่านริชเมนูหัวข้อ
                    “แลกของรางวัล”
                  </p>
                  <p>
                    13. ติดตามกิจกรรมพิเศษและศึกษารายละเอียดเพิ่มเติมได้ที่ LINE
                    OA @koalas-march
                  </p>
                  <p>
                    ข้าพเจ้าได้อ่านและเข้าใจ กติกาและเงื่อนไขนี้เป็นอย่างดีแล้ว
                    และให้ความยินยอมให้ข้อมูลแก่บริษัทฯ
                    ในการใช้ข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อส่งข้อมูลกิจกรรมส่งเสริมการขายและการตลาดต่าง
                    ๆ ที่บริษัทฯ ต้องการจะสื่อสารในแคมเปญต่อๆ ไป
                  </p>
                  <h2>
                    กติกาการสะสมคาแรคเตอร์และการสุ่มเจอ Lucky Koala’s March
                    เพื่อรับรางวัลหมอนคู่พรีเมียมมูลค่าคู่ละ 1,098 บาท
                  </h2>
                  <p>
                    <strong>
                      <u>ขั้นตอนที่หนึ่ง</u>
                    </strong>
                  </p>
                  <p>
                    <li>
                      ซื้อโคอะลา มาร์ช ทุกขนาดและทุกรสชาติ (ยกเว้นโคอะลา
                      มาร์ชขนาด 5 บาท 10 บาทและรสมะม่วงและสัปปะรด)
                      ที่ร้านค้าชั้นนำทั่วประเทศ
                    </li>
                  </p>
                  <p>
                    <li>
                      และทำการแอดไลน์ทางการ @koalas-march
                      ทำการลงทะเบียนบนลิงก์ในริชเมนูพร้อมกรอกรายละเอียด
                      ชื่อ-นามสกุล เบอร์โทรศัพท์ โดย 1 บัญชีไลน์
                      สามารถลงทะเบียนได้เพียง 1 ครั้งเท่านั้น
                    </li>
                  </p>
                  <p>
                    <li>
                      และหลังจากนั้นผู้ร่วมกิจกรรมต้องกรอกรหัสในกล่องจำนวน 13
                      หลักหรือ 14 หลักส่งเข้าระบบผ่านริชเมนูหัวข้อ กรอกรหัส โดย
                      1 รหัสของขนาด 33 กรัม และ 37 กรัม มีค่าเท่ากับ 3
                      การ์ดสุ่มคาแรคเตอร์ และ 1 รหัสของขนาด 195 กรัม
                      มีค่าเท่ากับ 9
                      การ์ดสุ่มคาแรคเตอร์สามารถซื้อและส่งรหัสในกล่องเพื่อรับการ์ดสุ่มคาแรคเตอร์ได้ตั้งแต่วันที่
                      1 พ.ค. พ.ศ. 2567 และจะสิ้นสุดลงในวันที่ 31 พ.ย. พ.ศ. 2567
                    </li>
                  </p>
                  <p>
                    <strong>
                      <u>ขั้นตอนที่สอง</u>
                    </strong>
                  </p>
                  <p>
                    <li>
                      กดแลกรับของรางวัลได้เมื่อสะสมคาแรคเตอร์ครบทั้ง 214 ตัว
                      หรือ เมื่อสุ่มเจอ Lucky Koala’s March ได้ที่ระบบ Line
                      Offical Account @koalas-march
                    </li>
                  </p>
                </div>
              </div> */}

              {!noBTN && (
                <div className="bBtn">
                  <a onClick={goBack} className="btn">
                    ย้อนกลับ
                  </a>
                </div>
              )}
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PolicyTerms;
