import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import helper from "services/helper";
import ModalReceipt from "./modalReceipt";
import heic2any from "heic2any";
import ModalContext from "contexts/ModalContext";
const {
  CheckDupReceipt,
  CreateReciept,
  GetUploadReceiptSchedual,
} = require("services/Api/Module/Customer");

const UploadReceipt = () => {
  const [recieptNum, setRecieptNum] = useState("");
  const [recieptStatus, setRecieptStatus] = useState(null);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [opemModalReceipt, setOpenModalReceipt] = useState(false);
  const [receiptImage, setReceiptImage] = useState(null);
  const [receiptDisable, setReceiptDisable] = useState(true);
  const [imageName, setImageName] = useState("");
  const [refreshInput, setRefreshInput] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const stateProfile = useSelector((state) => state.profile.dataUser);
  const { mAlert } = useContext(ModalContext);
  const handleCheckReciept = async (value) => {
    const prepareCheckDup = {
      receiptNo: value,
      customerId: stateProfile.id,
    };
    if (value.length === 12) {
      setRecieptNum(value);
      const resultReciept = await handleCheckDupReceipt(prepareCheckDup);
      if (!resultReciept.status) {
        setReceiptDisable(true);
        setRecieptStatus("reject");
      } else {
        setReceiptDisable(false);
        setRecieptStatus("accept");
      }
    }
  };

  const recieptPayload = async () => {
    const recieptData = {
      customer: stateProfile.id ?? 0,
      pointEarnConfig: 4,
      receiptNo: recieptNum,
      receiptImg: receiptImage,
    };
    setIsLoading(true);
    const result = await createReciept(recieptData);
    setIsLoading(false);
    if (result.status) {
      navigate("/reciept-complete");
    } else {
      navigate("/scanpoint");
    }
  };

  const createReciept = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CreateReciept({ data: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "เกิดข้อผิดพลาด",
          _subTitle = "กรุณาลองใหม่อีกครั้ง";
        mAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
          onExit: () => {
            navigate("/scanpoint");
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const handleOpenreceipt = () => {
    setOpenReceipt(!openReceipt);
  };

  // const handleReceiptImagOld = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       setReceiptImage(reader.result);
  //       setImageName(file.name);
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleReceiptImage = async (event) => {
    setIsLoading(true);
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    const MAX_SIZE_FILE = helper.CONFIG.MAX_SIZE_FILE;
    const IMG_TYPE_FILE = helper.CONFIG.IMG_TYPE_FILE;
    if (selectedFile.size >= MAX_SIZE_FILE) {
      return alert("Your image size limit maximum");
    }
    if (IMG_TYPE_FILE.includes(selectedFile.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setReceiptImage(reader.result);
        setImageName(selectedFile.name);
      };
      reader.readAsDataURL(selectedFile);
    } else if (
      `${selectedFile.name}`.includes(".HEIC") ||
      `${selectedFile.name}`.includes(".heic")
    ) {
      const blob = await heic2any({ blob: selectedFile });

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.split(",")[1];
        const base64Small = await helper.IMAGE.toImageSmall(base64String);
        // setReceiptImage(reader.result);
        setReceiptImage(base64Small);
        setImageName(selectedFile.name);
      };
      reader.readAsDataURL(blob);
    } else {
      return alert(
        "Your image file type is not supported. We accept JPG, JPEG and PNG only."
      );
    }
    setIsLoading(false);
  };

  // -----------CHECK DUP SKU----------------
  const handleCheckDupReceipt = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CheckDupReceipt({ params: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  const fetchUploadReceiptSchedual = async (payload) => {
    let _return = {
      // status: false,
      // result: null,
      resultCode: null,
    };

    try {
      const { data } = await GetUploadReceiptSchedual({ params: payload });
      _return.resultCode = data.resultCode;
      if (data.resultCode === "20000") {
        setIsShow(true);
      }
      // }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  useEffect(() => {
    fetchUploadReceiptSchedual();
  }, []);
  if (isShow) {
    return (
      <section id="pUploadReceipt">
        <div className="bAccord">
          <div
            className={`btnAccord ${openReceipt ? "opened" : ""}`}
            onClick={() => setOpenReceipt(!openReceipt)}
          >
            <i></i>กรอกใบเสร็จ 7-11 รับ Point เพิ่ม X2 <br /> รสริช มัทฉะ เท่านั้น
          </div>
          <div
            className="contentAccord"
            style={{ display: openReceipt ? "block" : "none" }}
          >
            <div className="bForm">
              <div className="bTitle">
                <div>
                  <h2>ข้อมูลใบเสร็จ</h2>
                  <a
                    onClick={() => setOpenModalReceipt(true)}
                    id="btnModalReceipt"
                  >
                    ตัวอย่างใบเสร็จ
                  </a>
                </div>
                <div className="bCondition">
                  <p style={{ marginBottom: 8 }}>
                    *โปรดระบุชื่อ-นามสกุลที่ตรงกับบัตรประชาชนเท่านั้น
                  </p>
                  <p>*1 ใบเสร็จกรอกได้เพียง 1 ครั้ง</p>
                </div>
              </div>
              <div className="bRow">
                <div
                  className={`control-group validate ${
                    recieptStatus === "accept"
                      ? "success"
                      : recieptStatus === "reject"
                      ? "error"
                      : ""
                  }
                            `}
                >
                  <div className="tTitle">เลขที่ใบเสร็จ</div>
                  <input
                    type="text"
                    name=""
                    value={recieptNum}
                    placeholder="xxxxxxxxxxxxxx"
                    onInput={helper.FNFORM.isUpperCase}
                    maxLength={12}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length < 12) {
                        setRecieptNum(value);
                        setRecieptStatus("");
                        setReceiptDisable(true);
                      } else {
                        handleCheckReciept(value);
                      }
                    }}
                  />
                  <label for="" className="tError">
                    เลขที่ใบเสร็จถูกใช้งานไปแล้ว
                  </label>
                </div>
              </div>
              <div className="bRow">
                <div className="bBtnUpload">
                  <label className="btnUpload">
                    {refreshInput && (
                      <input
                        type="file"
                        onChange={handleReceiptImage}
                        disabled={isLoading || receiptDisable}
                      />
                    )}
                    <p className="btn outline">อัปโหลดใบเสร็จ</p>
                  </label>
                  <div className={`bTagFile ${receiptImage ? "" : "hide"}`}>
                    <div className="bTag">
                      <span>{imageName}</span>
                      <a
                        className="btnDelete"
                        onClick={() => {
                          setReceiptImage(!receiptImage);
                          setRefreshInput(false);
                          setTimeout(() => setRefreshInput(true), 200);
                          setIsLoading(false);
                        }}
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bBtn">
              <button
                // href="receipt-complete.php"
                className="btn"
                type="button"
                disabled={
                  !receiptImage ||
                  !(recieptNum.length === 12) ||
                  isLoading ||
                  receiptDisable
                }
                onClick={recieptPayload}
              >
                ยืนยัน
              </button>
              <button
                type="button"
                className="btn outline"
                onClick={handleOpenreceipt}
                disabled={isLoading}
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
        {/* <Menu /> */}
        <ModalReceipt
          isOpen={opemModalReceipt}
          onClose={() => setOpenModalReceipt(false)}
        />
      </section>
    );
  }
  return <div></div>;
};

export default UploadReceipt;
